import {formatTableData} from 'utils/util';

const PAYPWD_FINDBACK_START = 'zb/PAYPWD_FINDBACK_START';
const PAYPWD_FINDBACK_SUCCESS = 'zb/PAYPWD_FINDBACK_SUCCESS';
const PAYPWD_FINDBACK_FAILED = 'zb/PAYPWD_FINDBACK_FAILED';

const MODIFY_PAYPWD_START = 'zb/MODIFY_PAYPWD_START';
const MODIFY_PAYPWD_SUCCESS = 'zb/MODIFY_PAYPWD_SUCCESS';
const MODIFY_PAYPWD_FAILED = 'zb/MODIFY_PAYPWD_FAILED';

const RESET_PAYPWD_START = 'zb/RESET_PAYPWD_START';
const RESET_PAYPWD_SUCCESS = 'zb/RESET_PAYPWD_SUCCESS';
const RESET_PAYPWD_FAILED = 'zb/RESET_PAYPWD_FAILED';

let initialState = {

};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PAYPWD_FINDBACK_SUCCESS:
      return {
        ...state
      };
    case MODIFY_PAYPWD_SUCCESS:
      return {
        ...state
      };
    case RESET_PAYPWD_SUCCESS:
      return {
        ...state
      };
    default:
      return state;
  }
}

// 发送获取验证码的邮箱
export function payPwdFindBack(data = {}) {
  return {
    types: [PAYPWD_FINDBACK_START, PAYPWD_FINDBACK_SUCCESS, PAYPWD_FINDBACK_FAILED],
    promise: (client) => client.get('merLog/payPwd/findBack', {
      params: data,
    })
  };
}

// 修改支付密码
export function modifyPayPwd(data = {}) {
  return {
    types: [MODIFY_PAYPWD_START, MODIFY_PAYPWD_SUCCESS, MODIFY_PAYPWD_FAILED],
    promise: (client) => client.post('merLog/modifyPayPwd', {
      data: data,
    })
  };
}

// 重置支付密码
export function resetPayPwd(data = {}) {
  return {
    types: [RESET_PAYPWD_START, RESET_PAYPWD_SUCCESS, RESET_PAYPWD_FAILED],
    promise: (client) => client.post('merLog/payPwd/modify', {
      data: data,
    })
  };
}



