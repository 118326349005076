import {formatTableData} from 'utils/util';

const RECHARGERECORD_LIST_START = 'zb/RECHARGERECORD_LIST_START';
const RECHARGERECORD_LIST_SUCCESS = 'zb/RECHARGERECORD_LIST_SUCCESS';
const RECHARGERECORD_LIST_FAILED = 'zb/RECHARGERECORD_LIST_FAILED';

const ADD_RECHARGERECORD_START = 'zb/ADD_RECHARGERECORD_START';
const ADD_RECHARGERECORD_SUCCESS = 'zb/ADD_RECHARGERECORD_SUCCESS';
const ADD_RECHARGERECORD_FAILED = 'zb/ADD_RECHARGERECORD_FAILED';

const GET_CHARGEMERCHANT_LIST_START = 'zb/GET_CHARGEMERCHANT_LIST_START';
const GET_CHARGEMERCHANT_LIST_SUCCESS = 'zb/GET_CHARGEMERCHANT_LIST_SUCCESS';
const GET_CHARGEMERCHANT_LIST_FAILED = 'zb/GET_CHARGEMERCHANT_LIST_FAILED';

const GET_CHARGELEVY_LIST_START = 'zb/GET_CHARGELEVY_LIST_START';
const GET_CHARGELEVY_LIST_SUCCESS = 'zb/GET_CHARGELEVY_LIST_SUCCESS';
const GET_CHARGELEVY_LIST_FAILED = 'zb/GET_CHARGELEVY_LIST_FAILED';

const GET_CHARGEBANK_LIST_START = 'zb/GET_CHARGEBANK_LIST_START';
const GET_CHARGEBANK_LIST_SUCCESS = 'zb/GET_CHARGEBANK_LIST_SUCCESS';
const GET_CHARGEBANK_LIST_FAILED = 'zb/GET_CHARGEBANK_LIST_FAILED';

let initialState = {
  rechargeRecordList: [],
  total: 0,
  rMerchantList: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RECHARGERECORD_LIST_SUCCESS:
      return {
        ...state,
        rechargeRecordList: formatTableData(action.resp.data && action.resp.data.list || []),
        total: action.resp.data && action.resp.data.total
      };
    case ADD_RECHARGERECORD_SUCCESS:
      return {
        ...state,
      };
    case GET_CHARGEMERCHANT_LIST_SUCCESS:
      return {
        ...state,
        rMerchantList: action.resp.data || []
      };
    case GET_CHARGELEVY_LIST_SUCCESS:
      return {
        ...state,
      };
    case GET_CHARGEBANK_LIST_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export function getRechargeRecordList(data = {}) {
  return {
    types: [RECHARGERECORD_LIST_START, RECHARGERECORD_LIST_SUCCESS, RECHARGERECORD_LIST_FAILED],
    promise: (client) => client.get('user/list', {
      params: data,
    })
  };
}

// 充值录入
export function addRechargeRecord(data = {}) {
  return {
    types: [ADD_RECHARGERECORD_START, ADD_RECHARGERECORD_SUCCESS, ADD_RECHARGERECORD_FAILED],
    promise: (client) => client.post('bill/charge/create', {
      data: data,
    })
  };
}

// 商户列表
export function getChargeMerchantList(data = {}) {
  return {
    types: [GET_CHARGEMERCHANT_LIST_START, GET_CHARGEMERCHANT_LIST_SUCCESS, GET_CHARGEMERCHANT_LIST_FAILED],
    promise: (client) => client.get('bill/charge/merchantList', {
      params: data,
    })
  };
}

// 税忧地列表
export function getChargeLevyList(data = {}) {
  return {
    types: [GET_CHARGELEVY_LIST_START, GET_CHARGELEVY_LIST_SUCCESS, GET_CHARGELEVY_LIST_FAILED],
    promise: (client) => client.get('bill/charge/levyList', {
      params: data,
    })
  };
}

// 代征通道列表
export function getChargeBankList(data = {}) {
  return {
    types: [GET_CHARGEBANK_LIST_START, GET_CHARGEBANK_LIST_SUCCESS, GET_CHARGEBANK_LIST_FAILED],
    promise: (client) => client.get('bill/charge/bankList', {
      params: data,
    })
  };
}
