const GET_MERCHANT_DETAIL_START = 'zb/GET_MERCHANT_DETAIL_START';
const GET_MERCHANT_DETAIL_SUCCESS = 'zb/GET_MERCHANT_DETAIL_SUCCESS';
const GET_MERCHANT_DETAIL_FAILED = 'zb/GET_MERCHANT_DETAIL_FAILED';

let initialState = {
  merchantInfo: {}
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_MERCHANT_DETAIL_SUCCESS:
      return {
        ...state,
        merchantInfo: action.resp.data
      }
    default:
      return state;
  }
}

export function getMerchantDetail(data = {}) {
  return {
    types: [GET_MERCHANT_DETAIL_START, GET_MERCHANT_DETAIL_SUCCESS, GET_MERCHANT_DETAIL_FAILED],
    promise: (client) => client.get('merchant/detail', {
      params: data,
    })
  };
}
