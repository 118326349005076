export default function clientMiddleware(client) {
  return ({dispatch, getState}) => {
    return next => action => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      const { promise, types, initRqstToken, ...rest } = action; // eslint-disable-line no-redeclare
      
      if (!promise) {
        return next(action);
      }

      const [START, SUCCESS, FAILED] = types;

      const rqstToken = initRqstToken || '' + Date.now();
      next({...rest, rqstToken, type: START});
      const actionPromise = promise(client);

      actionPromise.then(
        (resp) => {
          next({...rest, rqstToken, resp, type: SUCCESS});
        },
        (error) => {
          next({...rest, rqstToken, error, type: FAILED});
        }
      ).catch((error)=> {
        console.error('MIDDLEWARE ERROR:', error);
        console.log(error.stack);
        next({...rest, error, type: FAILED});
      });
      return { actionPromise, rqstToken };
    };
  };
}
