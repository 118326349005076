import {formatTableData} from 'utils/util';

const ROLE_LIST_START = 'zb/ROLE_LIST_START';
const ROLE_LIST_SUCCESS = 'zb/ROLE_LIST_SUCCESS';
const ROLE_LIST_FAILED = 'zb/ROLE_LIST_FAILED';

const UPDATE_ROLE_START = 'zb/UPDATE_ROLE_START';
const UPDATE_ROLE_SUCCESS = 'zb/UPDATE_ROLE_SUCCESS';
const UPDATE_ROLE_FAILED = 'zb/UPDATE_ROLE_FAILED';

const DELETE_ROLE_START = 'zb/DELETE_ROLE_START';
const DELETE_ROLE_SUCCESS = 'zb/DELETE_ROLE_SUCCESS';
const DELETE_ROLE_FAILED = 'zb/DELETE_ROLE_FAILED';

let initialState = {
  roleList: [],
  total: 0
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ROLE_LIST_SUCCESS:
      return {
        ...state,
        roleList: formatTableData(action.resp.data && action.resp.data.list || []),
        total: action.resp.data && action.resp.data.total
      };
    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
      };
    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export function getRoleList(data = {}) {
  data.channel = 'type2';
  return {
    types: [ROLE_LIST_START, ROLE_LIST_SUCCESS, ROLE_LIST_FAILED],
    promise: (client) => client.get('system/role/list', {
      params: data,
    })
  };
}


export function updateRole(data = {}) {
  data.channel = 'type2';
  return {
    types: [UPDATE_ROLE_START, UPDATE_ROLE_SUCCESS, UPDATE_ROLE_FAILED],
    promise: (client) => client.post('system/role/save', {
      data: data,
    })
  };
}


export function deleteRole(data = {}) {
  return {
    types: [DELETE_ROLE_START, DELETE_ROLE_SUCCESS, DELETE_ROLE_FAILED],
    promise: (client) => client.delete('system/role/delete', {
      params: data,
    })
  };
}