import {formatTableData} from 'utils/util';

const ADDRECORD_LIST_START = 'zb/ADDRECORD_LIST_START';
const ADDRECORD_LIST_SUCCESS = 'zb/ADDRECORD_LIST_SUCCESS';
const ADDRECORD_LIST_FAILED = 'zb/ADDRECORD_LIST_FAILED';

let initialState = {
  addRecordList: [],
  total: 0
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADDRECORD_LIST_SUCCESS:
      return {
        ...state,
        addRecordList: formatTableData(action.resp.data && action.resp.data.list || []),
        total: action.resp.data && action.resp.data.total
      };
    default:
      return state;
  }
}

export function getAddRecordList(data = {}) {
  return {
    types: [ADDRECORD_LIST_START, ADDRECORD_LIST_SUCCESS, ADDRECORD_LIST_FAILED],
    promise: (client) => client.get('soho/importRecord', {
      params: data,
    })
  };
}
