import {formatTableData} from 'utils/util';

const PAYHISTORY_LIST_START = 'zb/PAYHISTORY_LIST_START';
const PAYHISTORY_LIST_SUCCESS = 'zb/PAYHISTORY_LIST_SUCCESS';
const PAYHISTORY_LIST_FAILED = 'zb/PAYHISTORY_LIST_FAILED';


const GET_SETTLE_STATISTICS_START = 'zb/GET_SETTLE_STATISTICS_START';
const GET_SETTLE_STATISTICS_SUCCESS = 'zb/GET_SETTLE_STATISTICS_SUCCESS';
const GET_SETTLE_STATISTICS_FAILED = 'zb/GET_SETTLE_STATISTICS_FAILED';

const EXPORT_MERPLAT_START = 'zb/EXPORT_MERPLAT_START';
const EXPORT_MERPLAT_SUCCESS = 'zb/EXPORT_MERPLAT_SUCCESS';
const EXPORT_MERPLAT_FAILED = 'zb/EXPORT_MERPLAT_FAILED';

const EXPORT_HUIDANMERPLAT_START = 'zb/EXPORT_HUIDANMERPLAT_START';
const EXPORT_HUIDANMERPLAT_SUCCESS = 'zb/EXPORT_HUIDANMERPLAT_SUCCESS';
const EXPORT_HUIDANMERPLAT_FAILED = 'zb/EXPORT_HUIDANMERPLAT_FAILED';

let initialState = {
  payHistoryList: [],
  total: 0
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PAYHISTORY_LIST_SUCCESS:
      return {
        ...state,
        payHistoryList: formatTableData(action.resp.data && action.resp.data.list || []),
        total: action.resp.data && action.resp.data.total
      };
    case EXPORT_MERPLAT_SUCCESS:
      return {
        ...state,
      };
    case EXPORT_HUIDANMERPLAT_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export function getPayHistoryList(data = {}) {
  return {
    types: [PAYHISTORY_LIST_START, PAYHISTORY_LIST_SUCCESS, PAYHISTORY_LIST_FAILED],
    promise: (client) => client.get('finance/settle/orderList', {
      params: data,
    })
  };
}

// 导出excel
export function exportMerPlat(data = {}) {
  return {
    types: [EXPORT_MERPLAT_START, EXPORT_MERPLAT_SUCCESS, EXPORT_MERPLAT_FAILED],
    promise: (client) => client.get('export/merPlat', {
      params: data,
      downloadFile: {name: '付款记录.xlsx'}
    })
  };
}

// 导出回单excel
export function exportHuidanMerPlat(data = {}) {
  return {
    types: [EXPORT_HUIDANMERPLAT_START, EXPORT_HUIDANMERPLAT_SUCCESS, EXPORT_HUIDANMERPLAT_FAILED],
    promise: (client) => client.get('export/vouchers', {
      params: data,
      downloadFile: {name: '结算回单.zip'}
    })
  };
}

// 批次付款记录列表-统计信息查询
export function getSettleStatistics(data = {}) {
  return {
    types: [GET_SETTLE_STATISTICS_START, GET_SETTLE_STATISTICS_SUCCESS, GET_SETTLE_STATISTICS_FAILED],
    promise: (client) => client.get('finance/settle/statistics', {
      params: data,
    })
  };
}