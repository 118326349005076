

const CREATE_TASK_START = 'zb/CREATE_TASK_START';
const CREATE_TASK_SUCCESS = 'zb/CREATE_TASK_SUCCESS';
const CREATE_TASK_FAILED = 'zb/CREATE_TASK_FAILED';

const TASK_DETAIL_START = 'zb/TASK_DETAIL_START';
const TASK_DETAIL_SUCCESS = 'zb/TASK_DETAIL_SUCCESS';
const TASK_DETAIL_FAILED = 'zb/TASK_DETAIL_FAILED';

let initialState = {

};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CREATE_TASK_SUCCESS:
      return {
        ...state,
      };
    case TASK_DETAIL_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
}

// 任务发布
export function createTask(data = {}) {
  return {
    types: [CREATE_TASK_START, CREATE_TASK_SUCCESS, CREATE_TASK_FAILED],
    promise: (client) => client.post('job/createJob', {
      data: data,
    })
  };
}

// 任务详情查询
export function getTaskDetail(data = {}) {
  return {
    types: [TASK_DETAIL_START, TASK_DETAIL_SUCCESS, TASK_DETAIL_FAILED],
    promise: (client) => client.get('job/jobDetail', {
      params: data,
    })
  };
}
