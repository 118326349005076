import {formatTableData} from 'utils/util';

const PAYMERCHANT_LIST_START = 'zb/PAYMERCHANT_LIST_START';
const PAYMERCHANT_LIST_SUCCESS = 'zb/PAYMERCHANT_LIST_SUCCESS';
const PAYMERCHANT_LIST_FAILED = 'zb/PAYMERCHANT_LIST_FAILED';

const UPLOAD_PAYMENTFILE_START = 'zb/UPLOAD_PAYMENTFILE_START';
const UPLOAD_PAYMENTFILE_SUCCESS = 'zb/UPLOAD_PAYMENTFILE_SUCCESS';
const UPLOAD_PAYMENTFILE_FAILED = 'zb/UPLOAD_PAYMENTFILE_FAILED';

const GET_PAYMENTINFO_START = 'zb/GET_PAYMENTINFO_START';
const GET_PAYMENTINFO_SUCCESS = 'zb/GET_PAYMENTINFO_SUCCESS';
const GET_PAYMENTINFO_FAILED = 'zb/GET_PAYMENTINFO_FAILED';

const GET_TEMPUPLOAD_LIST_START = 'zb/GET_TEMPUPLOAD_LIST_START';
const GET_TEMPUPLOAD_LIST_SUCCESS = 'zb/GET_TEMPUPLOAD_LIST_SUCCESS';
const GET_TEMPUPLOAD_LIST_FAILED = 'zb/GET_TEMPUPLOAD_LIST_FAILED';

const BATCHPAY_SUBMIT_START = 'zb/BATCHPAY_SUBMIT_START';
const BATCHPAY_SUBMIT_SUCCESS = 'zb/BATCHPAY_SUBMIT_SUCCESS';
const BATCHPAY_SUBMIT_FAILED = 'zb/BATCHPAY_SUBMIT_FAILED';

const GET_TASKTEMPLATE_LIST_START = 'zb/GET_TASKTEMPLATE_LIST_START';
const GET_TASKTEMPLATE_LIST_SUCCESS = 'zb/GET_TASKTEMPLATE_LIST_SUCCESS';
const GET_TASKTEMPLATE_LIST_FAILED = 'zb/GET_TASKTEMPLATE_LIST_FAILED';

const EXPORT_MERPLAT_START = 'zb/EXPORT_MERPLAT_START';
const EXPORT_MERPLAT_SUCCESS = 'zb/EXPORT_MERPLAT_SUCCESS';
const EXPORT_MERPLAT_FAILED = 'zb/EXPORT_MERPLAT_FAILED';

let initialState = {
  payMerchantList: [],
  tmpUploadlist: [],
  tmpTotal: 0,
  taskList: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PAYMERCHANT_LIST_SUCCESS:
      return {
        ...state,
        payMerchantList: action.resp.data,
      };
    case UPLOAD_PAYMENTFILE_SUCCESS:
      return {
        ...state
      };
    case GET_PAYMENTINFO_SUCCESS:
      return {
        ...state
      };
    case GET_TEMPUPLOAD_LIST_SUCCESS:
      return {
        ...state,
        tmpUploadlist: formatTableData(action.resp.data && action.resp.data.list || []),
        tmpTotal: action.resp.data && action.resp.data.total
      };
    case BATCHPAY_SUBMIT_SUCCESS:
      return {
        ...state
      };
    case GET_TASKTEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        taskList: action.resp.data,
      };
    default:
      return state;
  }
}

// 付款商户列表
export function getPayMerchantList(data = {}) {
  return {
    types: [PAYMERCHANT_LIST_START, PAYMERCHANT_LIST_SUCCESS, PAYMERCHANT_LIST_FAILED],
    promise: (client) => client.get('bill/charge/merchantList', {
      params: data,
    })
  };
}

// 获取商户列表
export function getAutoMerchantList(data = {}) {
  return {
    types: [PAYMERCHANT_LIST_START, PAYMERCHANT_LIST_SUCCESS, PAYMERCHANT_LIST_FAILED],
    promise: (client) => client.get('merchant/getList', {
      params: data,
    })
  };
}

// 上传付款文件
export function uploadPaymentFile(data = {}) {
  return {
    types: [UPLOAD_PAYMENTFILE_START, UPLOAD_PAYMENTFILE_SUCCESS, UPLOAD_PAYMENTFILE_FAILED],
    promise: (client) => client.post('finance/settle/settle', {
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  };
}

// 选择付款商户+服务商后，查询付款信息
export function getPaymentInfo(data = {}) {
  return {
    types: [GET_PAYMENTINFO_START, GET_PAYMENTINFO_SUCCESS, GET_PAYMENTINFO_FAILED],
    promise: (client) => client.get('finance/settle/information', {
      params: data,
    })
  };
}

// 临时上传记录-列表查询
export function getTempUploadList(data = {}) {
  return {
    types: [GET_TEMPUPLOAD_LIST_START, GET_TEMPUPLOAD_LIST_SUCCESS, GET_TEMPUPLOAD_LIST_FAILED],
    promise: (client) => client.get('finance/settle/tmpList', {
      params: data,
    })
  };
}

// 导出excel
export function tmpBatchSettle(data = {}) {
  return {
    types: [EXPORT_MERPLAT_START, EXPORT_MERPLAT_SUCCESS, EXPORT_MERPLAT_FAILED],
    promise: (client) => client.get('export/tmpBatchSettle', { //merchant/export/tmpBatchSettle
      params: data,
      downloadFile: {name: '错误信息.xlsx'}
    })
  };
}

// 提交
export function batchPaySubmit(data = {}) {
  return {
    types: [BATCHPAY_SUBMIT_START,BATCHPAY_SUBMIT_SUCCESS,BATCHPAY_SUBMIT_FAILED],
    promise: (client) => client.post('finance/settle/submit', {
      data: data,
    })
  };
}

// 任务模板查询接口
export function getTaskTemplateList(data = {}) {
  return {
    types: [GET_TASKTEMPLATE_LIST_START, GET_TASKTEMPLATE_LIST_SUCCESS, GET_TASKTEMPLATE_LIST_FAILED],
    promise: (client) => client.get('job/boxList', {
      params: data,
    })
  };
}


