import {formatTableData} from 'utils/util';

const GET_BILLADDRESS_LIST_START = 'zb/GET_BILLADDRESS_LIST_START';
const GET_BILLADDRESS_LIST_SUCCESS = 'zb/GET_BILLADDRESS_LIST_SUCCESS';
const GET_BILLADDRESS_LIST_FAILED = 'zb/GET_BILLADDRESS_LIST_FAILED';

const ADD_BILLADDRESS_START = 'zb/ADD_BILLADDRESS_START';
const ADD_BILLADDRESS_SUCCESS = 'zb/ADD_BILLADDRESS_SUCCESS';
const ADD_BILLADDRESS_FAILED = 'zb/ADD_BILLADDRESS_FAILED';

const UPDATE_BILLADDRESS_START = 'zb/UPDATE_BILLADDRESS_START';
const UPDATE_BILLADDRESS_SUCCESS = 'zb/UPDATE_BILLADDRESS_SUCCESS';
const UPDATE_BILLADDRESS_FAILED = 'zb/UPDATE_BILLADDRESS_FAILED';

const DELETE_BILLADDRESS_START = 'zb/DELETE_BILLADDRESS_START';
const DELETE_BILLADDRESS_SUCCESS = 'zb/DELETE_BILLADDRESS_SUCCESS';
const DELETE_BILLADDRESS_FAILED = 'zb/DELETE_BILLADDRESS_FAILED';

let initialState = {
  billManageList: [],
  total: 0,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_BILLADDRESS_LIST_SUCCESS:
      return {
        ...state,
        billManageList: formatTableData(action.resp.data && action.resp.data.list || []),
        total: action.resp.data && action.resp.data.total
      };
    case ADD_BILLADDRESS_SUCCESS:
      return {
        ...state,
      };
    case UPDATE_BILLADDRESS_SUCCESS:
      return {
        ...state,
      };
    case DELETE_BILLADDRESS_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
}

// 开票地址管理列表
export function getBillAddressList(data = {}) {
  return {
    types: [GET_BILLADDRESS_LIST_START, GET_BILLADDRESS_LIST_SUCCESS, GET_BILLADDRESS_LIST_FAILED],
    promise: (client) => client.get('merchant/postAddress/list', {
      params: data,
    })
  };
}

// 开票地址管理-新增
export function addBillAddress(data = {}) {
  return {
    types: [ADD_BILLADDRESS_START, ADD_BILLADDRESS_SUCCESS, ADD_BILLADDRESS_FAILED],
    promise: (client) => client.post('merchant/postAddress/new', {
      data: data,
    })
  };
}

// 开票地址管理-修改
export function updateBillAddress(data = {}) {
  return {
    types: [UPDATE_BILLADDRESS_START, UPDATE_BILLADDRESS_SUCCESS, UPDATE_BILLADDRESS_FAILED],
    promise: (client) => client.put('merchant/postAddress/update', {
      data: data,
    })
  };
}

// 开票地址管理-修改
export function delBillAddress(data = {}) {
  return {
    types: [DELETE_BILLADDRESS_START, DELETE_BILLADDRESS_SUCCESS, DELETE_BILLADDRESS_FAILED],
    promise: (client) => client.delete('merchant/postAddress/delete', {
      params: data,
    })
  };
}

