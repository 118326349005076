import { formatTableData } from 'utils/util';

const GET_GETCONTRACTSETTLIST_START = 'zb/GET_GETCONTRACTSETTLIST_START';
const GET_GETCONTRACTSETTLIST_SUCCESS = 'zb/GET_GETCONTRACTSETTLIST_SUCCESS';
const GET_GETCONTRACTSETTLIST_FAILED = 'zb/GET_GETCONTRACTSETTLIST_FAILED';

const DOWNLOAD_CONTRACT_TEMPLATE_START = 'zb/DOWNLOAD_CONTRACT_TEMPLATE_START';
const DOWNLOAD_CONTRACT_TEMPLATE_SUCCESS = 'zb/DOWNLOAD_CONTRACT_TEMPLATE_SUCCESS';
const DOWNLOAD_CONTRACT_TEMPLATE_FAILED = 'zb/DOWNLOAD_CONTRACT_TEMPLATE_FAILED';

const ADD_SENDADDCONTRACTSETTDATA_START = 'zb/ADD_SENDADDCONTRACTSETTDATA_START';
const ADD_SENDADDCONTRACTSETTDATA_SUCCESS = 'zb/ADD_SENDADDCONTRACTSETTDATA_SUCCESS';
const ADD_SENDADDCONTRACTSETTDATA_FAILED = 'zb/ADD_SENDADDCONTRACTSETTDATA_FAILED';

const ADD_DELETECONTRACTSETT_START = 'zb/ADD_DELETECONTRACTSETT_START';
const ADD_DELETECONTRACTSETT_SUCCESS = 'zb/ADD_DELETECONTRACTSETT_SUCCESS';
const ADD_DELETECONTRACTSETT_FAILED = 'zb/ADD_DELETECONTRACTSETT_FAILED';

const UPDATE_CONTRACT_START = 'zb/UPDATE_CONTRACT_START';
const UPDATE_CONTRACT_SUCCESS = 'zb/UPDATE_CONTRACT_SUCCESS';
const UPDATE_CONTRACT_FAILED = 'zb/UPDATE_CONTRACT_FAILED';

const GET_CONTRACT_STATUS_START = 'zb/GET_CONTRACT_STATUS_START';
const GET_CONTRACT_STATUS_SUCCESS = 'zb/GET_CONTRACT_STATUS_SUCCESS';
const GET_CONTRACT_STATUS_FAILED = 'zb/GET_CONTRACT_STATUS_FAILED';

const DELETE_CONTRACT_START = 'zb/DELETE_CONTRACT_START';
const DELETE_CONTRACT_SUCCESS = 'zb/DELETE_CONTRACT_SUCCESS';
const DELETE_CONTRACT_FAILED = 'zb/DELETE_CONTRACT_FAILED';

const GET_CONTRACT_TYPE_START = 'zb/GET_CONTRACT_TYPE_START';
const GET_CONTRACT_TYPE_SUCCESS = 'zb/GET_CONTRACT_TYPE_SUCCESS';
const GET_CONTRACT_TYPE_FAILED = 'zb/GET_CONTRACT_TYPE_FAILED';

const GET_MERCHANT_LIST_START = 'zb/GET_MERCHANT_LIST_START';
const GET_MERCHANT_LIST_SUCCESS = 'zb/GET_MERCHANT_LIST_SUCCESS';
const GET_MERCHANT_LIST_FAILED = 'zb/GET_MERCHANT_LIST_FAILED';

const GET_UPLOADCONTRAVT_INFO_START = 'zb/GET_UPLOADCONTRAVT_INFO_START';
const GET_UPLOADCONTRAVT_INFO_SUCCESS = 'zb/GET_UPLOADCONTRAVT_INFO_SUCCESS';
const GET_UPLOADCONTRAVT_INFO_FAILED = 'zb/GET_UPLOADCONTRAVT_INFO_FAILED';

const DELETE_CONTRACT_FILE_START = 'zb/DELETE_CONTRACT_FILE_START';
const DELETE_CONTRACT_FILE_SUCCESS = 'zb/DELETE_CONTRACT_FILE_SUCCESS';
const DELETE_CONTRACT_FILE_FAILED = 'zb/DELETE_CONTRACT_FILE_FAILED';

let initialState = {
  contractList: [],
  total: 0,
  contractStatus: [],
  contractType: [],
  merchantList: [],
  contractFileList: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_GETCONTRACTSETTLIST_SUCCESS:
      console.log('action--', action)
      return {
        ...state,
        contractSettList: action.resp.data.list,
        contractSettTotal: action.resp.data.total
      };
    case DOWNLOAD_CONTRACT_TEMPLATE_SUCCESS:
      return {
        ...state
      };
    case ADD_SENDADDCONTRACTSETTDATA_SUCCESS:
      return {
        ...state
      };
    case ADD_DELETECONTRACTSETT_SUCCESS:
      return {
        ...state
      };
    case UPDATE_CONTRACT_SUCCESS:
      return {
        ...state
      };
    case GET_CONTRACT_STATUS_SUCCESS:
      return {
        ...state,
        contractStatus: action.resp.data
      };
    case DELETE_CONTRACT_SUCCESS:
      return {
        ...state
      };
    case GET_CONTRACT_TYPE_SUCCESS:
      return {
        ...state,
        contractType: action.resp.data
      };
    case GET_MERCHANT_LIST_SUCCESS:
      return {
        ...state,
        merchantList: formatTableData(action.resp.data && action.resp.data.list || []),
      };
    case GET_UPLOADCONTRAVT_INFO_SUCCESS:
      return {
        ...state,
        contractFileList: action.resp.data && action.resp.data.list || []
      };
    case DELETE_CONTRACT_FILE_SUCCESS:
      return {
        ...state
      };
    default:
      return state;
  }
}

// 合同结算列表
export function getContractSettList(data = {}) {
  return {
    types: [GET_GETCONTRACTSETTLIST_START, GET_GETCONTRACTSETTLIST_SUCCESS, GET_GETCONTRACTSETTLIST_FAILED],
    promise: (client) => client.get('contract/settle/list', {
      params: data,
    })
  };
}
// 新增结算合同
export function sendAddContractSettData(data = {}) {
  return {
    types: [ADD_SENDADDCONTRACTSETTDATA_START, ADD_SENDADDCONTRACTSETTDATA_SUCCESS, ADD_SENDADDCONTRACTSETTDATA_FAILED],
    promise: (client) => client.post('contract/settle/save', {
      data: data,
    })
  };
}
// 删除结算合同
export function deleteContractSett(data = {}) {
  return {
    types: [ADD_DELETECONTRACTSETT_START, ADD_DELETECONTRACTSETT_SUCCESS, ADD_DELETECONTRACTSETT_FAILED],
    promise: (client) => client.post(`contract/settle/delete/${data.id}`, {
      data: data,
    })
  };
}

// 合同类型
export function getContractType(data = {}) {
  return {
    types: [GET_CONTRACT_TYPE_START, GET_CONTRACT_TYPE_SUCCESS, GET_CONTRACT_TYPE_FAILED],
    promise: (client) => client.get('merchant/contract/getType', {
      params: data,
    })
  };
}

// 合同状态
export function getContractStatus(data = {}) {
  return {
    types: [GET_CONTRACT_STATUS_START, GET_CONTRACT_STATUS_SUCCESS, GET_CONTRACT_STATUS_FAILED],
    promise: (client) => client.get('merchant/contract/getState', {
      params: data,
    })
  };
}

// 下载合同模板
export function downloadContractTemplate(data = {}) {
  return {
    types: [DOWNLOAD_CONTRACT_TEMPLATE_START, DOWNLOAD_CONTRACT_TEMPLATE_SUCCESS, DOWNLOAD_CONTRACT_TEMPLATE_FAILED],
    promise: (client) => client.get('data/download', {
      params: { url: data.url },
      downloadFile: { name: data.name }
    })
  };
}



// 修改自定义合同
export function updateContract(data = {}) {
  return {
    types: [UPDATE_CONTRACT_START, UPDATE_CONTRACT_SUCCESS, UPDATE_CONTRACT_FAILED],
    promise: (client) => client.post('merchant/contract/update', {
      data: data,
    })
  };
}

// 删除自定义合同
export function deleteContract(data = {}) {
  return {
    types: [DELETE_CONTRACT_START, DELETE_CONTRACT_SUCCESS, DELETE_CONTRACT_FAILED],
    promise: (client) => client.delete('merchant/contract/delete', {
      params: data,
    })
  };
}

// 商户列表
export function getMerchantList(data = {}) {
  return {
    types: [GET_MERCHANT_LIST_START, GET_MERCHANT_LIST_SUCCESS, GET_MERCHANT_LIST_FAILED],
    promise: (client) => client.get('merchant/list', {
      params: data,
    })
  };
}

// 实时获取上传的文件
export function getUploadContractInfo(data = {}) {
  return {
    types: [GET_UPLOADCONTRAVT_INFO_START, GET_UPLOADCONTRAVT_INFO_SUCCESS, GET_UPLOADCONTRAVT_INFO_FAILED],
    promise: (client) => client.get('merchant/contract/dataList', {
      params: data,
    })
  };
}

// 删除已上传的合同文件
export function deleteContractFile(data = {}) {
  return {
    types: [DELETE_CONTRACT_FILE_START, DELETE_CONTRACT_FILE_SUCCESS, DELETE_CONTRACT_FILE_FAILED],
    promise: (client) => client.delete('data/delete', {
      params: data,
    })
  };
}


