// 正则匹配


// 格式化菜单数据组成一维数组
export const formatMenuList = (menuList,result = []) => {
  menuList.map(menu => {
    result.push(menu);
    if(menu.children){
      formatMenuList(menu.children,result);
    }
  })
  return result;
}

// 获取面包屑数据
export const getBreadcrumbList = (menuList,record,type) => {
  let breadcrumbList = [];
  const fMenuList = formatMenuList(menuList);
  let target = {};
  if(record.id){
    target = fMenuList.find(i => i.id === record.id);
  }else{
    target = fMenuList.find(i => i.url === formatUrl(record));
  }
  breadcrumbList.unshift(target);
  let parent = {};
  let grandParent = {};
  if(target && target.parentId){
    parent = fMenuList.find(i => i.id === target.parentId);
    breadcrumbList.unshift(parent);
    if(parent.parentId){
      grandParent = fMenuList.find(i => i.id === parent.parentId);
      breadcrumbList.unshift(grandParent);
    }
  }
  if(type === 'breadcrumb'){
    return breadcrumbList;
  }else if(type === 'menuKey'){
    return breadcrumbList.map(i => i.key + '');
  }
}

export const EXTRAMENUFORFOURTH = [
  { key: 'detail', value: "详情" },
  { key: 'add', value: "新增" },
  {key: 'recruitmentInfo',value: '招募信息'},
  {key: 'template',value: '模板'},
  {key: 'task',value: '任务'},
  {key: 'publishTask',value: '发布任务'},
  {key: 'pay',value: '付款'},
];

// 优化面包屑显示
export const getCurrentBreadcrumb = (route, menu) => {
  let routeArray = route.split('/');
  routeArray = routeArray.filter(i => i !== '');
  let routes = routeArray.map(item => {return {route:item,select:0}}) // 添加一个select来判断url切割数组里面在menu和extraMenu都找不到的特殊字符，比如查看详情绑定的id
  let breadcrumb = [];
  let initOpenKeys = [];
  let selectedKeyDeep = [];
  // 在menu找到对应的内容
  ergodicMenu(menu,routes,breadcrumb,selectedKeyDeep,initOpenKeys);  
  // 在extraMenu找到对应的内容
  EXTRAMENUFORFOURTH.map(exitem => {
    if(routes.findIndex(i => i.route === exitem.key) !== -1){
      const obj = {};
      obj.key = exitem.key;
      obj.title = exitem.value;
      breadcrumb[routes.findIndex(i => i.route === exitem.key)] = obj;
      routes[routes.findIndex(i => i.route === exitem.key)].select = 1;
    }
  })
  // 在extraMenu和menu都找不到的内容
  routes.map(item => {
    if(item.route && item.select === 0){
      const obj = {};
      obj.key = '';
      obj.title = item.route;
      breadcrumb[routes.findIndex(i => i.route === item.route)] = obj;
    }
  })
  return {breadcrumb,selectedKeyDeep,initOpenKeys}
}
// menu遍历查到对应的菜单内容
const ergodicMenu = (menu,routes,breadcrumb,selectedKeyDeep,initOpenKeys) => {
  menu.map((menuitem) => {
    if(routes.findIndex(i => i.route === menuitem.key) !== -1){
      const obj = {};
      obj.key = menuitem.key;
      obj.title = menuitem.menu;
      breadcrumb[routes.findIndex(i => i.route === menuitem.key)] = obj;
      routes[routes.findIndex(i => i.route === menuitem.key)].select = 1;
      initOpenKeys.push(menuitem.key);
    }
    if(menuitem.children){
      ergodicMenu(menuitem.children,routes,breadcrumb,selectedKeyDeep,initOpenKeys);
    }else{
      if(routes.findIndex(i => i.route === menuitem.key) !== -1){
        selectedKeyDeep.push(menuitem.key);
      }
    }
  })
}

// 判断url是否含有detail，edit，add等,菜单里面的子集页面
const formatUrl = (url) => {
  let urlArr = url.split('/');
  if(urlArr.length > 4){
    const _urlArr = urlArr.slice(0,4);
    return _urlArr.join('/');
  }else{
    return url;
  }
}


// 格式化table数据，给每一项加上key
export const formatTableData = (data) => {
  return data.map((item,index) => {
    if(item.id){
      return Object.assign(item,{key: item.id});
    }else{
      return Object.assign(item,{key: index+1});
    }
  })
}

// 上传图片获取base64码
export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}


export const uploadImgUrl = "https://loan.reedsec.com/store/image/upload";  // 图片上传地址

export const joinImgUrl = "https://loan.reedsec.com/store/image/";    // 图片拼接地址

// 处理搜索数据，给select组件绑定数据
export const formSearchFormData = (res,searchFormData,key,callback) => {
  searchFormData.forEach(item => {
    if(item.key == key){
      if(key == 'levyName' || key == 'levyId'){
        item.optionList = (res.data || []).map(d => {
          return Object.assign({
            value: d.id,
            label: d.name
          })
        })
      }else{
        item.optionList = res.data
      }
    }
  })
  console.log('searchFormData--',searchFormData)
  callback && callback(searchFormData);
}

// 使用正则处理身份证掩码显示
export const maskprocesseID = (str) => {
  if(str){
    str=str.replace(/\s+/g,"");
    return str ? str.replace(/^(.{6})(?:\d+)(.{3})/, "$1*********$2") : ''
  }else{
    return '';
  }
}

// 使用正则处理银行卡号掩码显示
export const maskprocesseBank = (str) => {
  if(str){
    str=str.replace(/\s+/g,"");
    return str ? str.replace(/^(.{4})(?:\d+)(.{4})/, "$1********$2") : ''
  }else{
    return '';
  }
}

// 使用正则处理手机号掩码显示
export const maskprocessePhone = (str) => {
  if(str){
    str=str.replace(/\s+/g,"");
    return str ? str.replace(/^(.{3})(?:\d+)(.{4})/, "$1****$2") : ''
  }else{
    return '';
  }
}

export const maskprocessePhoneV = (str) => {
  if(str){
    str=str.replace(/\s+/g,"");
    return str ? str.replace(/^(.{7})(?:\d+)(.{0})/, "$1****$2") : ''
  }else{
    return '';
  }
}

export const telReg = /^1[2-9][\d]{9}$/;
export const emailReg = /^[\da-z]+([\-\.\_]?[\da-z]+)*@[\da-z]+([\-\.]?[\da-z]+)*(\.[a-z]{2,})+$/i;
export const moneyReg = /^[\d]+\.?[\d]{0,2}$/;
export const bankCardReg = /^[1-9][\d]{15,18}$/;
export const idCardReg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X|x)$/;
export const carCodeReg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/;
export const passwordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{4,20}$/;
export const passwordSReg = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{4,20}$/;
export const nameReg = /^[\u4e00-\u9fa5]{2,8}$/;
export const nameSReg = /^[\u4e00-\u9fa5]{4,20}$/;
export const roleNameReg = /^[a-zA-Z\u4e00-\u9fa5]{2,20}$/;
export const userNameReg = /^[A-Za-z0-9]{4,20}$/;
export const acountNameReg = /^[A-Za-z0-9\u4e00-\u9fa5]{4,50}$/;
export const bankNameReg = /^[A-Za-z0-9\u4e00-\u9fa5]{4,20}$/;
export const serialNumberReg = /^[A-Za-z0-9]{5,30}$/;


// 金额转换
export const convertPrice = (value) => {
  if (!value && value !== 0) {
    return '';
  }
  // const priceRemainder = value % 100;
  // let priceInteger = parseInt(value / 100) + '';
  // let newpricestr = "";
  // let newprice = "";
  // let count = 0;
  // if (priceInteger.length > 3) {
  //   for (let i = priceInteger.length - 1; i >= 0; i--) {
  //     if (count % 3 == 0 && count != 0) {
  //       newpricestr = priceInteger.charAt(i) + "," + newpricestr;
  //     } else {
  //       newpricestr = priceInteger.charAt(i) + newpricestr;
  //     }
  //     count++;
  //   }
  //   priceInteger = newpricestr;
  //   if (priceRemainder == 0) {
  //     newprice = priceInteger + '.00';
  //   } else {
  //     newprice = priceInteger + '.' + priceRemainder;
  //   }
  // } else {
  //   priceInteger = priceInteger;
  //   if (priceRemainder == 0) {
  //     newprice = priceInteger + '.00';
  //   } else {
  //     newprice = priceInteger + '.' + priceRemainder;
  //   }
  // }
  // return newprice;
  // return (value || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
  var parts = value.toString().split(".");  
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");  
  return parts.join("."); 
};

export const downloadFile = (file) => {
  let blob = new Blob([file.originFileObj]);
  let downloadElement = document.createElement('a');
  let href = window.URL.createObjectURL(blob);
  downloadElement.href = href;
  downloadElement.download = file.name;
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
  window.URL.revokeObjectURL(href);
}

export const formatBankNo = (str) => {
  str=str.replace(/\s+/g,"");
  return str.slice(-4);
}

export const toChinese = (i) => {
  var arr = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"]
  var unit = ["", "十", "百", "千", "万",'十万']
  var arr1 = i.toString().split("").reverse()
  var str = ''
  arr1.forEach(function(value, index) {
   var v = parseInt(value)
   if (v === 0) {
    str = arr1[index-1] > 0 ? arr[v] + str : str
   } else {
    str = arr1.length == 2 && index == 1 && v == 1 ? '十' + str : arr[v] + unit[index] + str
   }
  })
  return str||"零"
 }


 export const formatDateString = (fullDateStr, timeOnly = false) => {
  if (!fullDateStr || typeof fullDateStr !== 'string' && typeof fullDateStr !== 'number') {
    return '';
  }
  const date = new Date(fullDateStr);
  // const date = new Date(fullDateStr.replace("-", "/").replace("-", "/"));

  return formatDate(date, timeOnly, fullDateStr);
};


export const formatDate = (date, timeOnly = false, fullDateStr) => {
  if (!date) {
    return '';
  }

  const timeStr = date.toLocaleTimeString('en', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });
  if (timeOnly) {
    return `${timeStr}`;
  } else {
    // Use 'en' can force the format to mm/dd/yyyy
    const dateStr = date.toLocaleDateString('en', {
      month: '2-digit',
      year: 'numeric',
      day: '2-digit'
    });
    const dateParts = dateStr.split('/');
    // return `${dateParts[2]}-${dateParts[0]}-${dateParts[1]} ${timeStr}`;
    return timestampToTime(fullDateStr);
  }
};


export const timestampToTime = (timestamp) => {
  const date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}
// 判断数字是否为个位，个位前面补0
export const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}