import {formatTableData} from 'utils/util';

const GET_OPENAPI_CONFIG_START = 'zb/GET_OPENAPI_CONFIG_START';
const GET_OPENAPI_CONFIG_SUCCESS = 'zb/GET_OPENAPI_CONFIG_SUCCESS';
const GET_OPENAPI_CONFIG_FAILED = 'zb/GET_OPENAPI_CONFIG_FAILED';

const UPDATE_OPENAPI_CONFIG_START = 'zb/UPDATE_OPENAPI_CONFIG_START';
const UPDATE_OPENAPI_CONFIG_SUCCESS = 'zb/UPDATE_OPENAPI_CONFIG_SUCCESS';
const UPDATE_OPENAPI_CONFIG_FAILED = 'zb/UPDATE_OPENAPI_CONFIG_FAILED';

const MODIFY_OPENAPI_CONFIG_START = 'zb/MODIFY_OPENAPI_CONFIG_START';
const MODIFY_OPENAPI_CONFIG_SUCCESS = 'zb/MODIFY_OPENAPI_CONFIG_SUCCESS';
const MODIFY_OPENAPI_CONFIG_FAILED = 'zb/MODIFY_OPENAPI_CONFIG_FAILED';

const MERCHANT_AUDIT_SWITCH_START = 'zb/MERCHANT_AUDIT_SWITCH_START';
const MERCHANT_AUDIT_SWITCH_SUCCESS = 'zb/MERCHANT_AUDIT_SWITCH_SUCCESS';
const MERCHANT_AUDIT_SWITCH_FAILED = 'zb/MERCHANT_AUDIT_SWITCH_FAILED';

let initialState = {

};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_OPENAPI_CONFIG_SUCCESS:
      return {
        ...state,
      };
    case UPDATE_OPENAPI_CONFIG_SUCCESS:
      return {
        ...state,
      };
    case MODIFY_OPENAPI_CONFIG_SUCCESS:
      return {
        ...state,
      };
    case MERCHANT_AUDIT_SWITCH_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
}

// 对外接口配置查询
export function getOpenApiConfig(data = {}) {
  return {
    types: [GET_OPENAPI_CONFIG_START, GET_OPENAPI_CONFIG_SUCCESS, GET_OPENAPI_CONFIG_FAILED],
    promise: (client) => client.get('openApi/config', {
      params: data,
    })
  };
}

// apikey更新
export function updateOpenApiConfig(data = {}) {
  return {
    types: [UPDATE_OPENAPI_CONFIG_START, UPDATE_OPENAPI_CONFIG_SUCCESS, UPDATE_OPENAPI_CONFIG_FAILED],
    promise: (client) => client.get('openApi/update', {
      params: data,
    })
  };
}

// 修改配置
export function modifyOpenApiConfig(data = {}) {
  return {
    types: [MODIFY_OPENAPI_CONFIG_START, MODIFY_OPENAPI_CONFIG_SUCCESS, MODIFY_OPENAPI_CONFIG_FAILED],
    promise: (client) => client.post('openApi/change', {
      data: data,
    })
  };
}

// 商户系统审核
export function merchantAuditSwitch(data = {}) {
  return {
    types: [MERCHANT_AUDIT_SWITCH_START, MERCHANT_AUDIT_SWITCH_SUCCESS, MERCHANT_AUDIT_SWITCH_FAILED],
    promise: (client) => client.get('merchant/auditSwitch', {
      params: data,
    })
  };
}
// 商户系统审核
export function messageCodeSwitch(data = {}) {
  return {
    types: [MERCHANT_AUDIT_SWITCH_START, MERCHANT_AUDIT_SWITCH_SUCCESS, MERCHANT_AUDIT_SWITCH_FAILED],
    promise: (client) => client.get('merchant/messageCodeSwitch', {
      params: data,
    })
  };
}


