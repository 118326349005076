import {formatTableData} from 'utils/util';

const PAYMENT_LIST_START = 'zb/PAYMENT_LIST_START';
const PAYMENT_LIST_SUCCESS = 'zb/PAYMENT_LIST_SUCCESS';
const PAYMENT_LIST_FAILED = 'zb/PAYMENT_LIST_FAILED';

const GET_SETTLE_STATISTICS_START = 'zb/GET_SETTLE_STATISTICS_START';
const GET_SETTLE_STATISTICS_SUCCESS = 'zb/GET_SETTLE_STATISTICS_SUCCESS';
const GET_SETTLE_STATISTICS_FAILED = 'zb/GET_SETTLE_STATISTICS_FAILED';

const UPDATE_SETTLE_MEMO_START = 'zb/UPDATE_SETTLE_MEMO_START';
const UPDATE_SETTLE_MEMO_SUCCESS = 'zb/UPDATE_SETTLE_MEMO_SUCCESS';
const UPDATE_SETTLE_MEMO_FAILED = 'zb/UPDATE_SETTLE_MEMO_FAILED';

const GET_BATCHDETAIL_LIST_START = 'zb/GET_BATCHDETAIL_LIST_START';
const GET_BATCHDETAIL_LIST_SUCCESS = 'zb/GET_BATCHDETAIL_LIST_SUCCESS';
const GET_BATCHDETAIL_LIST_FAILED = 'zb/GET_BATCHDETAIL_LIST_FAILED';

const GET_BATCHDETAIL_STATISTICS_START = 'zb/GET_BATCHDETAIL_STATISTICS_START';
const GET_BATCHDETAIL_STATISTICS_SUCCESS = 'zb/GET_BATCHDETAIL_STATISTICS_SUCCESS';
const GET_BATCHDETAIL_STATISTICS_FAILED = 'zb/GET_BATCHDETAIL_STATISTICS_FAILED';

const EXPORT_MERPLATBATCH_START = 'zb/EXPORT_MERPLATBATCH_START';
const EXPORT_MERPLATBATCH_SUCCESS = 'zb/EXPORT_MERPLATBATCH_SUCCESS';
const EXPORT_MERPLATBATCH_FAILED = 'zb/EXPORT_MERPLATBATCH_FAILED';

let initialState = {
  paymentList: [],
  total: 0,
  statisticsInfo: {},
  batchDetailList: [],
  batchDetailTotal: 0,
  batchStatistics: {}
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        paymentList: formatTableData(action.resp.data && action.resp.data.list || []),
        total: action.resp.data && action.resp.data.total
      };
    case GET_SETTLE_STATISTICS_SUCCESS:
      return {
        ...state,
        statisticsInfo: action.resp.data
      };
    case UPDATE_SETTLE_MEMO_SUCCESS:
      return {
        ...state,
      };
    case GET_BATCHDETAIL_LIST_SUCCESS:
      return {
        ...state,
        batchDetailList: formatTableData(action.resp.data && action.resp.data.list || []),
        batchDetailTotal: action.resp.data && action.resp.data.total
      };
    case GET_BATCHDETAIL_STATISTICS_SUCCESS:
      return {
        ...state,
        batchStatistics: action.resp.data
      };
    case EXPORT_MERPLATBATCH_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export function getPaymentList(data = {}) {
  return {
    types: [PAYMENT_LIST_START, PAYMENT_LIST_SUCCESS, PAYMENT_LIST_FAILED],
    promise: (client) => client.get('finance/settle/batchList', {
      params: data,
    })
  };
}

// 批次付款记录列表-统计信息查询
export function getSettleStatistics(data = {}) {
  return {
    types: [GET_SETTLE_STATISTICS_START, GET_SETTLE_STATISTICS_SUCCESS, GET_SETTLE_STATISTICS_FAILED],
    promise: (client) => client.get('finance/settle/statistics', {
      params: data,
    })
  };
}

// 修改备注
export function updateSettleMemo(data = {}) {
  return {
    types: [UPDATE_SETTLE_MEMO_START, UPDATE_SETTLE_MEMO_SUCCESS, UPDATE_SETTLE_MEMO_FAILED],
    promise: (client) => client.post('finance/settle/batchUpdate', {
      data: data,
    })
  };
}

// 付款记录--列表数据
export function getBatchDetailList(data = {}) {
  return {
    types: [GET_BATCHDETAIL_LIST_START, GET_BATCHDETAIL_LIST_SUCCESS, GET_BATCHDETAIL_LIST_FAILED],
    promise: (client) => client.get('finance/settle/batchDetailList', {
      params: data,
    })
  };
}

// 付款记录-- 付款统计详情
export function getBatchDetailStatistics(data = {}) {
  return {
    types: [GET_BATCHDETAIL_STATISTICS_START, GET_BATCHDETAIL_STATISTICS_SUCCESS, GET_BATCHDETAIL_STATISTICS_FAILED],
    promise: (client) => client.get('finance/settle/batchDetail', {
      params: data,
    })
  };
}

// 导出excel
export function exportMerPlatBatch(data = {}) {
  return {
    types: [EXPORT_MERPLATBATCH_START, EXPORT_MERPLATBATCH_SUCCESS, EXPORT_MERPLATBATCH_FAILED],
    promise: (client) => client.get('export/merPlatBatch', {
      params: data,
      downloadFile: {name: '批次付款记录.xlsx'}
    })
  };
}
// 导出详情excel
export function exportBatchDetail(data = {}) {
  return {
    types: [EXPORT_MERPLATBATCH_START, EXPORT_MERPLATBATCH_SUCCESS, EXPORT_MERPLATBATCH_FAILED],
    promise: (client) => client.get('export/exportBatchDetail', {
      params: data,
      downloadFile: {name: '批次付款记录明细.xlsx'}
    })
  };
}