import {formatTableData} from 'utils/util';

const RECHARGERECORD_LIST_START = 'zb/RECHARGERECORD_LIST_START';
const RECHARGERECORD_LIST_SUCCESS = 'zb/RECHARGERECORD_LIST_SUCCESS';
const RECHARGERECORD_LIST_FAILED = 'zb/RECHARGERECORD_LIST_FAILED';

const GET_RECHARGE_STATISTICS_START = 'zb/GET_RECHARGE_STATISTICS_START';
const GET_RECHARGE_STATISTICS_SUCCESS = 'zb/GET_RECHARGE_STATISTICS_SUCCESS';
const GET_RECHARGE_STATISTICS_FAILED = 'zb/GET_RECHARGE_STATISTICS_FAILED';

const GET_SENDEDITRECIPT_START = 'zb/GET_SENDEDITRECIPT_START';
const GET_SENDEDITRECIPT_SUCCESS = 'zb/GET_SENDEDITRECIPT_SUCCESS';
const GET_SENDEDITRECIPT_FAILED = 'zb/GET_SENDEDITRECIPT_FAILED';

let initialState = {
  rechargeRecordList: [],
  total: 0
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RECHARGERECORD_LIST_SUCCESS:
      return {
        ...state,
        rechargeRecordList: formatTableData(action.resp.data && action.resp.data.list || []),
        total: action.resp.data && action.resp.data.total
      };
    case GET_RECHARGE_STATISTICS_SUCCESS:
      return {
        ...state,
      };
    case GET_SENDEDITRECIPT_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
}

// 获取充值记录列表
export function getRechargeRecordList(data = {}) {
  return {
    types: [RECHARGERECORD_LIST_START, RECHARGERECORD_LIST_SUCCESS, RECHARGERECORD_LIST_FAILED],
    promise: (client) => client.get('bill/charge/list', {
      params: data,
    })
  };
}

// 获取充值统计数据
export function getRechargeStatistics(data = {}) {
  return {
    types: [GET_RECHARGE_STATISTICS_START, GET_RECHARGE_STATISTICS_SUCCESS, GET_RECHARGE_STATISTICS_FAILED],
    promise: (client) => client.get('bill/charge/statistics', {
      params: data,
    })
  };
}
// 保存编辑回单
export function sendEditRecipt(data = {}) {
  return {
    types: [GET_SENDEDITRECIPT_START, GET_SENDEDITRECIPT_SUCCESS, GET_SENDEDITRECIPT_FAILED],
    promise: (client) => client.post('/bill/charge/receipt/edit', {
      data: data,
    })
  };
}
